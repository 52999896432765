import { combineReducers, createStore } from 'redux';
import { useSelector } from 'react-redux';
import { Plan } from '../server/src/types';

export const SESSION_LOGIN = 'login';
export const SESSION_LOGOUT = 'login';

export const SELECT_PAYMENT = 'select-payment';

export const CHANGE_MOOD = 'change-mood';

export interface IAuthUser {
  ga_email: string,
  tg_id: number,
  tg_username: string,
  vk_id: null,
  vk_name: string,
  end?: string
  endTime?: number
}

export interface IMe {
  user: IAuthUser,
  tokens: IAuthTokens
}
export interface IAuthTokens {
  gToken?: string,
  vkToken?: string,
  tgUser?: ITelegramUser
}

interface ITelegramUser {
  id: number,
  first_name: string,
  last_name: string,
  username: string,
  photo_url: string,
  auth_date: number,
  hash: string
}

export interface IStore {
  me?: IMe
}

export interface IPlan {
  id: string
  name: string
  price: any
  buy?: string
}


export const store = createStore(
  combineReducers({
    me: (state = null, action) => {
      switch (action.type) {
        case SESSION_LOGIN:
          return action.me
        case SESSION_LOGOUT:
          return null
        default:
          return state
      }
    },
    payment: (state = null, action) => {
      switch (action.type) {
        case SELECT_PAYMENT:
          return {payment: action.payment}
        default:
          return state
      }
    },
    paymentLogoMood: (state = 'normal', action) => {
      switch (action.type) {
        case CHANGE_MOOD:
          return action.mood
        default:
          return state
      }
    },
  }),
  // @ts-ignore
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export const useSession = () => useSelector<IStore, IStore["me"]["user"]>(state => state?.me?.user)
export const useMe = () => useSelector<IStore, IStore["me"]>(state => state?.me)

