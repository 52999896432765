export interface GTokenPayload {
  aud: string
  azp: string
  email: string
  email_verified: boolean
  exp: number
  given_name: string
  iat: number
  iss: string
  jti: string
  name: string
  nbf: number
  picture: string
  sub: string
}


// @ts-ignore
const EXTENSION_ID: string = process.env.PREACT_APP_EXTENSION_ID;
export const DEVELOPMENT = process.env.NODE_ENV === 'development'
export const EXTENSION_LINK = `https://chrome.google.com/webstore/detail/tinder-swiper/${EXTENSION_ID}`

// @ts-ignore
const GOOGLE_CLIENT_ID: string = process.env.PREACT_APP_GOOGLE_CLIENT_ID;
const API: string = process.env.PREACT_APP_API || `${location.origin}/api`;

if (!EXTENSION_ID) {
  throw new Error('EXTENSION_ID is not defined')
}

if (!GOOGLE_CLIENT_ID) {
  throw new Error('GOOGLE_CLIENT_ID is not defined')
}

export {EXTENSION_ID, GOOGLE_CLIENT_ID, API}
