import {FunctionalComponent, h} from 'preact';
import { Router, useRouter } from 'preact-router';
import {Provider} from 'react-redux'
import {store} from '../store'
import { useCallback, useEffect, useRef } from 'react';
import Frame from "../routes/frame";
import MainLayout from "../routes/main";
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import { DEVELOPMENT } from '../types';
import Welcome from '../routes/welcome';
import { metrika, YM_CLIENT } from '../metrika';

const customScrollClass = 'custom-scroll'


const ym = metrika();

const App: FunctionalComponent = () => {
  // useEffect(() => {
  //   authenticate();
  // }, []);

  // return <Match path="/frame/:rest" >{
  //   ({ matches}: any) => matches ? <Frame/> : <MainLayout/>
  // }</Match>;

  const handleRoute = useCallback(e => {
    e.url.startsWith('/frame') ?
      document.body.classList.add(customScrollClass) :
      document.body.classList.remove(customScrollClass)

    ym?.(YM_CLIENT, 'hit', e.url)
  }, [])

  return <div>
  <Router onChange={handleRoute}>
    <Frame path="/frame/:rest" />
    <Welcome path="/welcome"/>
    <MainLayout default/>
  </Router>

  <link rel="preconnect" href="https://fonts.googleapis.com"/>
  <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous"/>
  <link
    href="https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap"
    rel="stylesheet"/>
</div>;
};

const AppWrapper: FunctionalComponent = () => {
  return <Provider store={store}>
    <UserAgentProvider ua={window.navigator.userAgent}>
      <App></App>
    </UserAgentProvider>
  </Provider>
};

export default AppWrapper
